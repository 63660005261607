// extracted by mini-css-extract-plugin
export var section = "services-module--section--D9BU_";
export var about = "services-module--about--otR4v";
export var illustration = "services-module--illustration--10R2M";
export var img = "services-module--img--A2Dfx";
export var contentWrapper = "services-module--content-wrapper--1yNgZ";
export var buttonBox = "services-module--button-box--3dBm_";
export var buttonWrapper = "services-module--button-wrapper--2iVsR";
export var readMoreButton = "services-module--read-more-button--116UP";
export var content = "services-module--content--18w7Y";
export var before = "services-module--before--1DoW_";
export var after = "services-module--after--PL9c_";
export var contactButton = "services-module--contact-button--167Tn";
export var service = "services-module--service--1WQE6";
export var priceWrapper = "services-module--price-wrapper--3dl8A";
export var priceItem = "services-module--price-item--OOoB8";
export var estTime = "services-module--est-time--165uS";
export var center = "services-module--center--1yNeE";
export var price = "services-module--price--2qgra";
export var getQuoteButton = "services-module--get-quote-button--aB6Hm";
export var service1 = "services-module--service-1--1qnZI";
export var service2 = "services-module--service-2--24-WF";
export var service3 = "services-module--service-3--3AIb9";
export var message = "services-module--message--33IEw";